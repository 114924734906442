<template>
  <div class="blockchain">
    <img src="../../assets/product/blockchain/1.png" class="headerimg" />

    <div class="server">
      <div class="title">产品及服务</div>
      <div class="serverlist">
        <div
          class="servercard"
          :style="
            'background-image:url(' +
            require('../../assets/product/blockchain/2.png') +
            ')'
          "
        >
          <div class="servertitle">区块链服务 BCS(Hyperledger Fabric)</div>
          <div class="serverinfo">
            基于软硬协同、自主创新、安全可控技术，简单易用，
            通过Kubernetes管理，数分钟内即可完成区块链网络的构建
          </div>
        </div>

        <div
          class="servercard"
          :style="
            'background-image:url(' +
            require('../../assets/product/blockchain/3.png') +
            ')'
          "
        >
          <div class="servertitle">可信数据交换与计算服务 TC3</div>
          <div class="serverinfo">
            为链上应用提供可信数据资产交换和可信联合分析计算，做到数据“可用不可见，可见不可得”
          </div>
        </div>
      </div>

      <div class="serverlist">
        <div
          class="servercard"
          :style="
            'background-image:url(' +
            require('../../assets/product/blockchain/4.png') +
            ')'
          "
        >
          <div class="servertitle">分布式身份服务 TDIS</div>
          <div class="serverinfo">
            为个人和企业用户提供统一的分布式身份标识，支持多场景、细粒度的可验证凭证管理能力
          </div>
        </div>

        <div
          class="servercard"
          :style="
            'background-image:url(' +
            require('../../assets/product/blockchain/5.png') +
            ')'
          "
        >
          <div class="servertitle">可信跨链数据链接服务 TCDAS</div>
          <div class="serverinfo">
            基于一系列可信区块链标准跨链协议，保证交易全栈可追溯，可扩展等，实现同构和异构链之间的互联互通
          </div>
        </div>
      </div>
    </div>

    <div class="advantage background">
      <div class="title">产品优势</div>
      <div class="advantagelist">
        <div class="advantageinfo">
          <div class="advantageheard">
            <img src="../../assets/product/blockchain/7.png" />
            <div class="advantagetext">开放共赢</div>
          </div>
          <div class="text">
            在 Hyperledger、Kubernetes 和 Docker
            等开源组件的基础上搭建，基于强大的社区和生态，支持友好接入
          </div>
        </div>
        <div class="advantageinfo">
          <div class="advantageheard">
            <img src="../../assets/product/blockchain/8.png" />
            <div class="advantagetext">可信协作</div>
          </div>
          <div class="text">
            提供了基于区块链技术的分布式身份服务，可信数据交换和计算服务，在隐私保护前提下，提升协作效率
          </div>
        </div>
        <div class="advantageinfo">
          <div class="advantageheard">
            <img src="../../assets/product/blockchain/9.png" />
            <div class="advantagetext">场景丰富</div>
          </div>
          <div class="text">
            已经落地多个行业场景，全方位覆盖金融、政府、银行、医疗、生活、零售、通用服务等众多行业
          </div>
        </div>
        <div class="advantageinfo">
          <div class="advantageheard">
            <img src="../../assets/product/blockchain/10.png" />
            <div class="advantagetext">高效灵活</div>
          </div>
          <div class="text">
            TPS性能和1000+的联盟节点网络，混合云、边缘云等部署管理能力，充分满足差异化组网诉求等
          </div>
        </div>
      </div>
    </div>

    <div class="solution">
      <div class="title">解决方案</div>

      <div class="solutionlist">
        <div class="solutioncard">
          <div class="solutionimg">
            <img src="../../assets/product/blockchain/11.png" />
          </div>
          <div class="solutioninfo">
            <div class="solutiontitle">疫情联防联控解决方案</div>
            <div class="solutiontext">
              疫情时期依据人的接触记录进行分析，可以有效地评估每个人的健康风险，实现精准防控。实时共享的特性，打造疫情联防联控解决方案，有效解决个人数据在共享使用中的实时性、可信性和隐私性等问题。在紧急情况结束后可转化为证照链、信用链等，进行常态化使用，实现平战结合、一网多用
            </div>
          </div>
        </div>
        <div class="solutioncard">
          <div class="solutionimg">
            <img src="../../assets/product/blockchain/12.png" />
          </div>
          <div class="solutioninfo">
            <div class="solutiontitle">区块链数字仓单解决方案</div>
            <div class="solutiontext">
              由于传统的大宗商品行业在交易、仓储、物流等环节难以确保贸易数据真实性和货物安全性，存在虚假贸易、内幕交易、商业欺诈、篡改数据等一系列不规范的交易行为风险，导致行业内生产、运输、销售各方难以从金融机构获取融资，行业长期存在融资难、融资贵等痛点
            </div>
          </div>
        </div>
        <div class="solutioncard">
          <div class="solutionimg">
            <img src="../../assets/product/blockchain/13.png" />
          </div>
          <div class="solutioninfo">
            <div class="solutiontitle">区块链商品溯源解决方案</div>
            <div class="solutiontext">
              本方案将区块链技术应用于溯源行业，为每件商品设定单独的识别码来确立商品身份，结合物联网技术将商品在供应链各环节产生的信息存储于区块链，实现上链信息无法篡改，以保障企业、消费者和监管部门在追溯商品信息时，查询到的数据真实有效
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="planAdvantag background">
      <div class="title">方案优势</div>

      <div class="planAdvantaglist">
        <div class="planAdvantagcard">
          <div class="planAdvantagimg">
            <img src="../../assets/product/blockchain/14.png" />
          </div>
          <div class="planAdvantaginfo">
            <div class="planAdvantagtitle">安全可靠</div>
            <div class="planAdvantagtext">
              在关键领域如共识算法、同态加密、零知识证明、高速网络连接、海量存储等方面具有自主知识产权的专利和技术积累。
            </div>
          </div>
        </div>
        <div class="planAdvantagcard">
          <div class="planAdvantagimg">
            <img src="../../assets/product/blockchain/15.png" />
          </div>
          <div class="planAdvantaginfo">
            <div class="planAdvantagtitle">可信增值</div>
            <div class="planAdvantagtext">
              提供安全计算、可信数据共享、分布式身份管理等服务，提升多方可信协作效率。
            </div>
          </div>
        </div>
      </div>

      <div class="planAdvantaglist">
        <div class="planAdvantagcard">
          <div class="planAdvantagimg">
            <img src="../../assets/product/blockchain/16.png" />
          </div>
          <div class="planAdvantaginfo">
            <div class="planAdvantagtitle">云链结合</div>
            <div class="planAdvantagtext">
              提供各种区块链需要的无限可扩展的资源和丰富多样的云计算产品、定制化的各行业解决方案，可以给企业带来更大的便利和价值。
            </div>
          </div>
        </div>
        <div class="planAdvantagcard">
          <div class="planAdvantagimg">
            <img src="../../assets/product/blockchain/17.png" />
          </div>
          <div class="planAdvantaginfo">
            <div class="planAdvantagtitle">合作开放</div>
            <div class="planAdvantagtext">
              专注于区块链底层技术和平台服务能力搭建，和各行业合作伙伴携手合作，共同打造基于华为区块链服务的可信行业区块链解决方案和区块链生态，共同推进区块链场景落地，帮助企业实现商业成功
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="case">
      <div class="title">客户案例</div>

      <div class="list top">
        <div class="card">
          <div class="img1">
            <img src="../../assets/product/blockchain/18.png" />
          </div>
          <div class="cardtitle">北京轻信局</div>
          <div class="info">
            北京市政府携手华为云，基于华为云区块链&ROMA打造“目录区块链”数据可信共享交易平台，解决数据共享难题，实现汇管用评一体的“聚通用”政府数据体，让加密数据在可信执行环境中进行联合计算分析，完成后销毁数据，并把结果同步给使用方。在使用后，北京市目录链实现了关键动作多方共识、行为可信、可溯，概要数据及时同步、提升业务效率30%+。
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="card">
          <div class="img1">
            <img src="../../assets/product/blockchain/19.png" />
          </div>
          <div class="cardtitle">华为供应链</div>
          <div class="info">
            华为将自有供应链体系与区块链技术相结合，对物流业务进行了重构：利用区块链强大的信息共享能力，华为物流将需要人工参与处理、消耗成本高昂、事物繁琐的供应链业务改造成省时、省力、省心的区块链物流解决方案，改进并提高了内部物流业务效率，解决了商品转移过程中的追溯防伪和信息变更同步问题，节约了20%+成本。
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="card">
          <div class="img2">
            <img src="../../assets/product/blockchain/20.png" />
          </div>
          <div class="cardtitle">华能智联</div>
          <div class="info">
            针对电力物资供应商融资难、融资贵的问题，华能智链以区块链技术为底层支撑，打造了电力行业供应链服务平台——“能信”平台，平台上有电厂、供应商、华能智链和融资方。基于区块链技术去中心化、不可篡改、可追溯、可拆分的主要特点，核心企业如华能电厂的信用可以向供应链末端传递。供应链上中小企业可凭借区块链的凭证向融资机构获得高效率供应链金融服务。
          </div>
          <div class="bebottom"></div>
        </div>
      </div>

      <div class="list top">
        <div class="card">
          <div class="img2">
            <img src="../../assets/product/blockchain/21.png" />
          </div>
          <div class="cardtitle">厦门云评众联</div>
          <div class="info">
            云评众联基于华为云区块链服务实现去中心化、透明可信的行业联盟链应用场景，构建权责有界、共生共赢的评估行业生态圈。通过将纸质数据电子化，电子数据标准化，云评众联节约了40%的管理成本，评估系统研发效率提升了40%，节约了在底层技术方面的资源和成本，聚焦业务创新，成功打造行业可信标杆。
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="card">
          <div class="img2">
            <img src="../../assets/product/blockchain/22.png" />
          </div>
          <div class="cardtitle">深圳市华傲数据</div>
          <div class="info">
            华傲数据基于华为云区块链的不可篡改、可追溯特性，打造电子证照系统，提供安全可靠的电子证照管理手段，进一步推进政务服务“一网通办”工作，加快数字政府建设。
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="card">
          <div class="img2">
            <img src="../../assets/product/blockchain/23.png" />
          </div>
          <div class="cardtitle">前海科创投</div>
          <div class="info">
            前海科创投基于华为云区块链服务打造了前海税务区块链税务管理服务云平台，以纳税人为中心，税、政双方“共通、共情、共治”，实现了云上便捷办事、云上高效办税。
          </div>
          <div class="bebottom"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.blockchain {
  .server {
    margin-top: 92px;
    margin-bottom: 113px;

    .serverlist {
      display: flex;
      margin-top: 48px;
      justify-content: center;

      .servercard {
        margin-left: 28px;
        width: 686px;
        height: 241px;
        background-size: 100%;
        padding: 50px 41px;
        box-sizing: border-box;

        .servertitle {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
        }

        .servertitle:hover {
          cursor: default;
          color: #2f7ffc;
        }

        .serverinfo {
          margin-top: 12px;
          font-size: 20px;
          font-weight: 500;
          color: #9e9e9e;
          width: 481px;
          line-height: 32px;
        }
      }
    }

    .servertop {
      margin-top: 28px;
    }
  }

  .background {
    background-image: url(../../assets/product/blockchain/6.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .advantage {
    padding: 71px;

    .advantagelist {
      display: flex;
      width: 80%;
      margin: auto;
      justify-content: space-between;
      margin-top: 100px;

      .advantageinfo {
        width: 285px;

        .advantageheard {
          img {
            height: 53px;
          }

          .advantagetext {
            margin-top: 36px;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
          }
        }

        .text {
          margin-top: 20px;
          font-size: 16px;
          font-weight: 500;
          color: #9b9c9d;
          line-height: 30px;
        }
      }
    }
  }

  .solution {
    margin-top: 92px;
    margin-bottom: 92px;

    .solutionlist {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: 0 auto;
      margin-top: 45px;

      .solutioncard:hover {
        .solutiontitle {
          color: #2f7ffc;
        }
      }

      .solutioncard {
        width: 450px;
        box-shadow: 0px 2px 22px 0px rgba(48, 48, 48, 0.13);
        border-radius: 20px;

        .solutionimg {
          img {
            width: 100%;
          }
        }

        .solutioninfo {
          padding: 0px 33px;
          padding-top: 37px;
          padding-bottom: 32px;

          .solutiontitle {
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
          }

          .solutiontext {
            margin-top: 21px;
            font-size: 14px;
            width: 385px;
            font-weight: 500;
            color: #9e9e9e;
            line-height: 24px;
          }
        }
      }
    }
  }

  .planAdvantag {
    padding: 82px;
    .planAdvantaglist {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      margin-top: 79px;

      .planAdvantagimg {
        display: inline-block;
        vertical-align: middle;
        width: 53px;

        img {
          min-width: 40px;
          max-width: 100%;
          height: auto;
        }
      }

      .planAdvantaginfo {
        width: 560px;
        vertical-align: middle;
        display: inline-block;
        margin-left: 36px;

        .planAdvantagtitle {
          font-size: 24px;
          font-weight: 500;
          color: #3d3d3d;
          line-height: 36px;
          cursor: default;
        }

        .planAdvantagtitle:hover {
          color: #2f7ffc;
        }

        .planAdvantagtext {
          margin-top: 12px;
          font-size: 20px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #9e9e9e;
          line-height: 32px;
        }
      }
    }
  }

  .case {
    margin-top: 92px;
    margin-bottom: 136px;
    .list {
      margin: 55px auto;
      display: flex;
      width: 80%;
      justify-content: space-between;

      .card:hover {
        .bebottom {
          background-color: #2f7ffc;
        }
      }
      .bebottom {
        border-radius: 0 0 20px 20px;
        width: 338px;
        height: 17px;
        position: relative;
        margin-top: 152px;
        margin-left: 18px;
      }

      .card {
        cursor: pointer;
        width: 430px;
        height: 488px;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
        border-radius: 10px;
        border: 1px solid #e8e8e8;
        padding: 0 25px;
        box-sizing: border-box;
        padding-top: 34px;
        padding-bottom: 21px;
        .cardtitle {
          text-align: center;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          margin-top: 8px;
        }

        .info {
          margin-top: 9px;
          font-size: 20px;
          font-weight: 400;
          color: #9e9e9e;
          line-height: 32px;
          height: 188px;
        }

        .img1 {
          width: 60px;
          height: 60px;
          margin: auto;
        }

        .img2 {
          width: 140px;
          height: 60px;
          margin: auto;
        }

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
  }
}
</style>
